@font-face {
    font-family: "Kazesawa-Bold";
    src: url(./fonts/Kazesawa-Bold.ttf);
}

.madewith{
  color: #FFF;
  font-family: Kazesawa-bold;
  text-decoration: none;
}

.madewith:hover{
  color: #54ebc8;
}

.customNav{
    background-color: #1e1f1c;
    color: #fff;
}
.nav-title{
    color: #fff;
    font-size: 15px;
    font-family: Kazesawa-Bold;
}
.spotify-title-lg{
    margin-top: 10%;
    font-family: Kazesawa-Bold;
    font-size: 70px;
    /*color: black;*/
}
.spotify-artists-lg{
    font-family: Kazesawa-Bold;
    font-size: 40px;
    /*color: black;*/
    text-align: right;
    /*margin-top: -30px;*/
}

.spotify-player-heading {
	background-image: linear-gradient(120deg, #5cedff 0%, #f096ff 100%);
	background-repeat: no-repeat;
	background-size: 70% 0.5em;
	background-position: 50% 130%;
  text-align: center;
  font-weight: bold;
  padding-bottom: 2px;
  font-size: 20px;
  font-family: Kazesawa-Bold;
}

.artwork{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    width :300;
    height: 300;
    border-radius: 15px;
}

.welcome{
  font-family: Kazesawa-Bold;
  color: #fff;
}

a.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  position: relative;
  background: #0bd630;
  border: 1px solid #0bd630;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 0 45px 0 25px;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 1.3;
  text-align: left;
  text-decoration: none;
  transition-duration: 0.3s;
}
a.login:before {
  content: '';
  width: 8px;
  height: 8px;
  border: 0;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 25px;
  margin-top: -6px;
}
a.login:hover {
  background: #fff;
  color: #0bd630;
}
a.login:hover:before {
  border-top: 2px solid #0bd630;
  border-right: 2px solid #0bd630;
}

a.nologin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  position: relative;
  background: #edba11;
  border: 1px solid #edba11;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 0 45px 0 25px;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 1.3;
  text-align: left;
  text-decoration: none;
  transition-duration: 0.3s;
}
a.nologin:before {
  content: '';
  width: 8px;
  height: 8px;
  border: 0;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 25px;
  margin-top: -6px;
}
a.nologin:hover {
  background: #fff;
  color: #edba11;
}
a.nologin:hover:before {
  border-top: 2px solid #edba11;
  border-right: 2px solid #edba11;
}

.attention{
  color: white;
  text-align: center;
  font-size: 12px;
  line-height: 0.3;
}

.gr_title p{
  background: -webkit-linear-gradient( 60deg,#12d6df, #34eb64, #ffd94f,#f20aa1, #3b09de);
  background-size:400%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textAnime 10s infinite;
}
@keyframes textAnime{
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.frame-wrapper {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.frame-wrapper__video {
position: relative;
width: 100%;
height: 0;
padding-bottom: 56.25%;
overflow: hidden;
margin-bottom: 50px;
}

.frame-wrapper__video iframe {
width: 100%;
height: 100%;
position: absolute;
top: 0;
left: 0;
}

.youtube-title-lg{
  margin-top: -20px;
  margin-bottom: -20px;
  font-family: Kazesawa-Bold;
  font-size: 30px;
}

.kb{
  font-family: Kazesawa-bold;
}

body{
    margin: 0;
    min-height: 100vh;
    /*background-image:url("./backgrounds/2.jpg");*/
    background-size:cover;
    backdrop-filter: blur(17px) saturate(140%);
}

.WSStatus {
  margin-top: -50px;
  width: 10px;
  height: 10px;
  line-height: 5px;
  border-radius: 50%;
  display: inline-block;
}

.bb_lg{
    /*border-bottom: 2px solid white;*/
    padding-bottom: 50px;
    width: 1000px;
}

.seekbar{
    /*border-bottom: 5px solid white;*/
    padding-bottom: 25px;
    width: 500px;
    margin-left: auto;
}

input { border-style: none; background: transparent; outline: none; }
button { padding: 0; background: none; border: none; outline: none; }

@keyframes gradient {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  .webflow-style-input {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 2px;
    padding: 3px;
    background: rgba(57, 63, 84, 0.8);
    border-radius: 5px;
  }
  .webflow-style-input:after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    height: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-position: 0% 0%;
    background: linear-gradient(to right, #B294FF, #57E6E6, #FEFFB8, #57E6E6, #B294FF, #57E6E6);
    background-size: 500% auto;
    animation: gradient 3s linear infinite;
  }
  .webflow-style-input input {
    flex-grow: 1;
    color: #BFD2FF;
    font-size: 1rem;
    line-height: 2.4rem;
    vertical-align: middle;
  }
  .webflow-style-input input::-webkit-input-placeholder {
    color: #7881A1;
  }
  .webflow-style-input button {
    color: #7881A1;
    font-size: 2.4rem;
    line-height: 2.4rem;
    vertical-align: middle;
    transition: color 0.25s;
  }
  .webflow-style-input button:hover {
    color: #BFD2FF;
  }